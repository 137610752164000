<template>
	<div>
		<div class="table-responsive">
			<table class="table v-middle text-nowrap bg-transparent">
				<thead class="bg-light">
					<tr class>
						<th class="border-0">Customer Name</th>
						<th class="border-0">Loan Amount</th>
						<th class="border-0">Period In Months</th>
						<th class="border-0">Monthly Payment</th>
						<th class="border-0">Loan Type</th>
						<th class="border-0">Loan Officer</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="item in items" :key="item.id">
						<td>
							<div class="d-flex align-items-center">
								<div class="mr-2">
									<b-avatar variant="primary" :text="item.customerName.charAt(0)" />
								</div>
								<div class>
									<h5 class="mb-0">{{ item.customerName }}</h5>
								</div>
							</div>
						</td>
						<td>AWG {{ item.amount }}</td>
						<td>{{ item.periodInMonths }}</td>
						<td>AWG {{ item.monthlyPayment }}</td>
						<td>{{ item.loanTypeDescription }}</td>
						<td>{{ item.agentName }}</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</template>
<script>
export default {
	name: "TopLoans",
	props: {
		items: Array,
	},
	data: () => ({
		title: "TopLoans",
	}),
};
</script>
